:root {
  --amplify-primary-color: #1982c4;
  --amplify-primary-contrast: var(–amplify-white);
  --amplify-primary-tint: #3ea6e6;
  --amplify-primary-shade: #136192;
  --amplify-secondary-color: #000000;
  --amplify-secondary-contrast: var(–amplify-white);
  --amplify-secondary-tint: #000000;
  --amplify-secondary-shade: #000000;
  --amplify-tertiary-color: #ffca3a;
  --amplify-tertiary-contrast: #000000;
  --amplify-tertiary-tint: #ffd76a;
  --amplify-tertiary-shade: #e9ab00;
  --amplify-red: #ff595e;
}

header {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
